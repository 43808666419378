import { Button, FLLoader, FLTable } from "@fitplan/web-components"
import axios from "axios"
import React, { useEffect, useState } from "react"
import ArrowLeft from "../../assets/icons/arrow-left.png"
import ArrowRight from "../../assets/icons/arrow-right.png"
// import { format, parseISO, addMinutes, parse } from "date-fns"
import { getCookie } from "../../utils/utils"

const futureMaxSchedulesDate = new Date(
  new Date().setDate(new Date().getDate() + 20)
)

const CurrentBookingTable = ({ studioId, studioSlug }) => {
  const [sessionDate, setSessionDate] = useState(new Date())
  const [scheduleDate, setScheduleDate] = useState("")
  const [bookingData, setBookingData] = useState([])
  const [userId, setUserId] = useState("")
  const accessToken = getCookie("accessToken")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const userId = getCookie(["profile"])
    if (userId) {
      setUserId(JSON.parse(userId).sub)
    }
  }, [])
  const getOrdinalSuffix = day => {
    if (day >= 11 && day <= 13) {
      return "th"
    }
    switch (day % 10) {
      case 1:
        return "st"
      case 2:
        return "nd"
      case 3:
        return "rd"
      default:
        return "th"
    }
  }

  const handleFormatDate = () => {
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    }
    const formattedDate = sessionDate.toLocaleString("en-US", options)

    const day = sessionDate.getDate()
    const ordinalSuffix = getOrdinalSuffix(day)
    const formattedDay = `${day}${ordinalSuffix}`
    setScheduleDate(formattedDate.replace(String(day), formattedDay))
  }

  // Function to handle the previous date button click
  const prevDate = () => {
    const prevDay = new Date(sessionDate)
    prevDay.setDate(prevDay.getDate() - 1)
    setSessionDate(prevDay)
  }

  // Function to handle the next date button click
  const nextDate = () => {
    const nextDay = new Date(sessionDate)
    nextDay.setDate(nextDay.getDate() + 1)
    setSessionDate(nextDay)
  }

  const structureData = data => {
    const tempArr = []
    data?.bookings?.map(booking => {
      let tempObj = booking
      data?.classes?.find(className => {
        if (className?.classInfoId === booking?.classInfoId) {
          tempObj.className = className?.name
        }
      })
      tempArr.push(tempObj)
    })
    setBookingData(tempArr)
  }

  const getBoolingList = () => {
    handleFormatDate()

    let BASE_URL = process.env.GATSBY_API_BASE_URL

    if (userId) {
      setIsLoading(true)
      axios
        .get(`${BASE_URL}/users/${userId}/bookings`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(res => {
          setBookingData(res.data.bookings)
          structureData(res?.data)
          setIsLoading(false)
        })
        .catch(err => {
          setIsLoading(false)
          console.log(err)
        })
    }
  }

  useEffect(() => {
    getBoolingList?.()
  }, [sessionDate, studioId, userId])

  const handleCancelBooking = bookingId => {
    axios
      .delete(
        `${process.env.GATSBY_API_BASE_URL}/users/${userId}/bookings/${bookingId}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then(res => {
        getBoolingList?.()
      })
      .catch(err => {
        console.log(err)
      })
  }
  return (
    <div>
      {isLoading && <FLLoader loaderColor="white" />}
      <div className="schedule-date-container">
        <p>{scheduleDate}</p>
        <div className="navigation-icon-container">
          <div
            className="navigation-icon"
            onClick={sessionDate > new Date() ? prevDate : undefined}
            style={{
              opacity: sessionDate > new Date() ? 1 : 0.6,
            }}
          >
            <img src={ArrowLeft} alt="arrow-left" />
          </div>
          <div
            className="navigation-icon"
            onClick={
              futureMaxSchedulesDate >= sessionDate ? nextDate : undefined
            }
            style={{
              opacity: futureMaxSchedulesDate >= sessionDate ? 1 : 0.6,
            }}
          >
            <img src={ArrowRight} alt="arrow-right" />
          </div>
        </div>
      </div>

      <div className="schedule-list-table-container">
        <div className="find-schedule-table">
          {bookingData?.length > 0 ? (
            <FLTable
              columns={[
                {
                  header: "",
                  accessor: "bookingId",
                },
                {
                  header: "",
                  accessor: "className",
                },
                {
                  header: "",
                  accessor: "date",
                },
                {
                  header: "",
                  accessor: "coachIds",
                },
                {
                  header: "",
                  Cell: ({ original }) => (
                    <>
                      {!!bookingData.length &&
                        bookingData.map(item => (
                          <Button
                            label="Cancel"
                            classes={`studio-table-button`}
                            onClick={() => {
                              handleCancelBooking(item.bookingId)
                            }}
                          />
                        ))}
                    </>
                  ),
                },
              ]}
              data={bookingData}
              isBordered={false}
              classes={"schedule-table"}
              tableMainActions={[]}
            />
          ) : (
            <h1>No Record Found</h1>
          )}
        </div>
      </div>
    </div>
  )
}

export default CurrentBookingTable
