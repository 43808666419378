import React, { useState, useMemo, useEffect } from "react"
import { navigate } from "gatsby"
import { FLTabbedNav } from "@fitplan/web-components"
import StudioPlan from "../components/StudioPlan"
import axios from "axios"
import {
  deleteCrossDomainCookies,
  getCookie,
  setCrossSubdomainCookie,
  userManager,
} from "../utils/utils"

const ChooseStudioPlan = ({
  data,
  setPageStep,
  setPackageDetails,
  setShowLoader,
  step,
}) => {
  let studioId = data?.contentfulStudio?.apiId
  let productDetails = data?.contentfulProducts?.items

  let content = [
    {
      id: 1,
      name: "Studio Plans",
      modules: {
        items: [],
      },
    },
  ]
  const [pageList, setPageList] = useState({})
  const [tabItems, setTabItems] = useState(content)
  const [activeTab, setActiveTab] = useState(tabItems?.[0]?.id)
  const [userId, setUserId] = useState("")
  const accessToken = getCookie("accessToken")

  let BASE_URL = process.env.GATSBY_API_BASE_URL
  

  useEffect(() => {
    getStudioPackages()
  }, [])

  const getStudioPackages = async () => {
    setShowLoader(true)
    await axios
      .get(`${BASE_URL}/studios/${studioId}`)
      .then(res => {
        let data = [...tabItems]
        res.data.packages.forEach(element => {
          let productData = productDetails.find(
            item => item.productIDs === element.packageId
          )
          data[0].modules.items.push({
            description: {
              description: productData?.description?.description,
            },
            name: element.name,
            sku: element.packageId,
            renewal: element.autoRenews ? "Auto-renews monthly" : "",
            prices: element.price,
            productTitle: {
              id: element.packageId,
              childMarkdownRemark: {
                html: `<p>${element.name}</p>`,
              },
            },
            backgroundImage: productData?.backgroundImage,
          })
        })
        setTabItems(data)
        setShowLoader(false)
      })
      .catch(err => {
        console.error(err)
        setShowLoader(false)
      })
  }

  useMemo(() => {
    if (!activeTab) {
      setActiveTab(tabItems?.[0]?.id)
      const newTabItems = tabItems?.map(tabItem => {
        return {
          ...tabItem,
          modules: undefined,
        }
      })
      setTabItems(newTabItems)
    }
  }, [tabItems])

  useMemo(() => {
    let tempArr = {}
    const page = tabItems?.find(tabItem => tabItem.id === activeTab)

    if (page) {
      tempArr = page?.modules
    }
    setPageList(tempArr)
  }, [activeTab])

  const handleTabClick = tabId => {
    setActiveTab(tabId)
  }

  const getUserDetails = async packageDetail => {
    setShowLoader(true)
    axios
      .get(`${BASE_URL}/users/${userId}/studios/account`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(async res => {
        setShowLoader(false)
        if (!res?.data?.paymentProfiles) {
          return setPageStep("buy-credits")
        }

        setPageStep("package-details")
      })
      .catch(err => {
        setShowLoader(false)
        if (err?.response?.status === 400) {
          navigate(`/studios/${data?.contentfulStudio?.slug}/signup`, {
            state: {
              packageStep: {
                packageDetails: packageDetail,
                step: step,
              },
            },
          })
        }
        console.error(err)
      })
  }

  const handleNikeMemberLogin = (packageDetail) => {
    deleteCrossDomainCookies(["profile", "accessToken"])
    userManager.signinRedirect({
      state: {
        packageStep: {
          returnUrl: `/studios/${data?.contentfulStudio?.slug}/membership`,
          packageDetails: packageDetail,
          step: step,
        },
      },
      useReplaceToNavigate: true,
    })
  }

  // const handleAgreementRedirection = async () => {
  //   // check user has pending agreement or not
  //   const agreements = await getUserAgreements()
  //   const isAnyAgreementPending = agreements?.Agreements?.some(
  //     agreement => agreement.isCompleted === false
  //   )
  //   if (isAnyAgreementPending) {
  //     navigate(`/agreements`)
  //   } else {
  //     navigate(`/studios/${data?.contentfulStudio?.slug}/packages-details`)
  //   }
  //   return true
  //   // TODO: send user to buy credit screen
  // }

  // const getUserAgreements = async () => {
  //   return await axios
  //     .get(`${BASE_URL}/users/${userId}/studios/agreements/${studioId}`, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     })
  //     .then(res => {
  //       return res.data
  //     })
  //     .catch(err => {
  //       console.error(err)
  //     })
  // }

  useEffect(() => {
    const userId = getCookie(["profile"])
    if (userId) {
      setUserId(JSON.parse(userId).sub)
    }
  }, [])

  const onChooseButtonClick = packageDetails => {
    setPackageDetails(packageDetails)
    if (userId) {
      getUserDetails(packageDetails)
    } else {
      handleNikeMemberLogin(packageDetails)
    }
  }

  const onMoreInfoClick = packageDetails => {
    setPackageDetails(packageDetails)
    setPageStep("package-benefits")
  }

  return (
    <>
      <div className="membership-banner-container">
        <div className="membership-banner-content">
          <h4>CHOOSE YOUR STUDIO PLAN</h4>
        </div>
      </div>
      <div className="membership-plans-tabs">
        <FLTabbedNav
          tabItem={tabItems}
          tabAlignment={"center"}
          handleClick={handleTabClick}
          activeTabId={activeTab}
        />
        <StudioPlan
          content={pageList}
          onChooseButtonClick={onChooseButtonClick}
          onMoreInfoClick={onMoreInfoClick}
        />
      </div>
    </>
  )
}

export default ChooseStudioPlan
