import React from "react"
import Carousel from "../Carousel"
import Banner from "../Banner"
import MarkDown from "../markdown"

const StudioModules = ({ content, siteWideMeta }) => {
  return (
    <div>
      {content &&
        content?.map(module => {
          let secondaryModuleName = module?.internal?.type?.replace(
            "Contentful",
            ""
          )
          switch (secondaryModuleName) {
            case "Carousel":
              return <Carousel content={module} key={module.id} />
            case "Banner":
              return (
                <Banner
                  content={module}
                  key={module.id}
                  siteWideMeta={siteWideMeta}
                />
              )
            case "Markdown":
              return <MarkDown content={module} key={module.id} />
            default:
              return null
          }
        })}
    </div>
  )
}

export default StudioModules
