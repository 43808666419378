import {
  Button,
  FLCheckbox,
  FLForm,
  FLLoader,
  FLModal,
} from "@fitplan/web-components"
import axios from "axios"
import { graphql, navigate } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import * as Yup from "yup"
import Layout from "../components/layout"
import { getCookie } from "../utils/utils"
import useWindowDimensions from "../utils/useWindowDimensions"

const StudioAccountSignup = ({ data, sessionId, location }) => {
  const [studioId, setStudioId] = useState(data?.contentfulStudio?.apiId)
  const [studioDetails, setStudioDetails] = useState(data?.contentfulStudio)
  const [initialValue, setInitialValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    date: "",
    phone: "",
    location: "",
    consent: false,
    "Agree consent": false,
  })
  const [isMobileView, setIsMobileView] = useState(false)
  const [memberUnsuccessful, setMemberUnsuccessful] = useState(false)
  const [userId, setUserId] = useState("")
  const formikRef = useRef(null)
  const [accessToken, setAccessToken] = useState(getCookie("accessToken"))
  const { width } = useWindowDimensions()

  const createStudioValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Enter valid email address")
      .required("Email is required"),
    gender: Yup.string(),
    date: Yup.string(),
    phone: Yup.string().required("Phone number is required"),
    location: Yup.string(),
  })

  const [isUserValidateLoading, setIsUserValidateLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setStudioDetails(data?.contentfulStudio)
    setStudioId(data?.contentfulStudio?.apiId)
  }, [data])

  let BASE_URL = process.env.GATSBY_API_BASE_URL
  

  useEffect(() => {
    if (width > 1024) {
      setIsMobileView(false)
    } else {
      setIsMobileView(true)
    }
  }, [width])

  useEffect(() => {
    const userProfile = getCookie(["profile"])
    const getAccessToken = getCookie("accessToken")
    setAccessToken(getAccessToken)
    if (userProfile) {
      const userDetails = JSON.parse(userProfile)
      setInitialValue({
        ...initialValue,
        firstName: userDetails.given_name,
        lastName: userDetails.family_name,
        email: userDetails.email,
      })
      const newUserId = userDetails.sub
      setUserId(newUserId)
    } else {
      // TODO: redirect user to login screen
      navigate("/")
    }
  }, [])

  useEffect(() => {
    // once userid set then only check for the user details
    if (userId) {
      getUserDetails()
    }
  }, [userId])

  // const handleAgreementRedirection = async () => {
  //   // check user has pending agreement or not
  //   const agreements = await getUserAgreements?.()
  //   const isAnyAgreementPending = agreements?.Agreements?.some(
  //     agreement => agreement.isCompleted === false
  //   )
  //   if (isAnyAgreementPending) {
  //     navigate(
  //       `/studios/${studioDetails.slug}/sessions/${sessionId}/agreements`
  //     )
  //   }
  //   return true
  //   // TODO: send user to buy credit screen
  // }

  const getUserDetails = async () => {
    axios
      .get(`${BASE_URL}/users/${userId}/studios/account`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(async res => {
        // if (res.data.isMember || res.data.isProspect) {
        //   await handleAgreementRedirection?.()
        // }
        // if(res.data.isMember){
        navigate(`/studios/${data?.contentfulStudio?.slug}/membership`, {
          state: {
            packageStep: location.state.packageStep,
          },
        })
        // }
        setIsUserValidateLoading(false)
        setIsLoading(false)
      })
      .catch(err => {
        setIsUserValidateLoading(false)
        setIsLoading(false)
        console.error(err)
      })
  }

  // const getUserAgreements = async () => {
  //   return await axios
  //     .get(
  //       `${BASE_URL}/users/${userId}/studios/${studioId}/agreements`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       }
  //     )
  //     .then(res => {
  //       return res.data
  //     })
  //     .catch(err => {
  //       console.error(err)
  //     })
  // }

  const handleSubmit = async () => {
    // validate formik form
    if (!formikRef.current?.isValid) {
      formikRef.current.validateForm()
      return
    }

    setIsLoading(true)
    let data = { ...formikRef?.current?.values }
    data.schemaVersion = "1.0"
    data.userId = userId
    data.studioId = studioId

    await axios
      .post(`${BASE_URL}/users/${userId}/studios/account`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(res => {
        formikRef.current?.resetForm?.()
        getUserDetails?.(userId)
      })
      .catch(err => {
        setIsLoading(false)
        formikRef.current?.resetForm?.()
        setMemberUnsuccessful(true)
        // getUserDetails?.(userId)
      })
  }

  return isUserValidateLoading ? (
    <FLLoader loaderColor="white" />
  ) : (
    <>
      {memberUnsuccessful && (
        <div className="member-unsuccessful-modal">
          <FLModal onClose={() => setMemberUnsuccessful(false)}>
            <div className="member-modal">
              <div className="member-modal-container">
                <div className="member-unsuccessful-title">
                  <h4>
                    Membership
                    <br />
                    unsuccessful
                  </h4>
                </div>
                <div className="member-unsuccessful-description">
                  <p>
                    We were unable to confirm your membership. Please check the
                    information provided and try again.
                  </p>
                </div>
                <div className="member-unsuccessful-button-container">
                  <Button
                    label="Try again"
                    classes="banner-secondary-button-dark"
                    onClick={() => setMemberUnsuccessful(false)}
                  />
                </div>
              </div>
            </div>
          </FLModal>
        </div>
      )}
      <Layout header={data?.header}>
        {isLoading && <FLLoader loaderColor="white" />}
        <div className={`studio-account-signup-form-container`}>
          <FLForm
            ref={formikRef}
            onFormSubmit={handleSubmit}
            isShowLabels={true}
            fields={
              isMobileView
                ? [
                    [
                      {
                        name: "firstName",
                        label: "First Name",
                        inputType: "text",
                        inputProps: {
                          // placeholder: "First Name",
                        },
                      },
                      {
                        name: "lastName",
                        label: "Last Name",
                        inputType: "text",
                        inputProps: {
                          // placeholder: "Last Name",
                        },
                      },
                    ],
                    {
                      name: "email",
                      inputType: "email",
                      label: "Email",
                      inputProps: {
                        // placeholder: "Email",
                      },
                    },
                    {
                      name: "gender",
                      inputType: "select",
                      label: "Gender",
                      inputProps: {
                        // placeholder: "Gender",
                      },
                      options: [
                        { label: "Male", value: "m" },
                        { label: "Female", value: "f" },
                      ],
                    },
                    {
                      name: "date",
                      inputType: "date",
                      label: "Date of Birth (MM/DD/YY)",
                      inputProps: {
                        // placeholder: "Date of Birth (MM/DD/YY)",
                        autoComplete: "off",
                        className: "date-of-birth",
                      },
                    },
                    {
                      name: "phone",
                      label: "Phone Number",
                      inputType: "tel",
                      inputProps: {
                        // placeholder: "Phone Number",
                      },
                    },
                    {
                      name: "location",
                      label: "Studios",
                      inputType: "select",
                      inputProps: {
                        type: "select",
                        // placeholder: "NTC Los Olivos",
                        autoComplete: "off",
                      },
                      options: [
                        {
                          label: "NTC Los Olivos 1",
                          value: "NTC Los Olivos 1",
                        },
                        {
                          label: "NTC Los Olivos 2",
                          value: "NTC Los Olivos 2",
                        },
                        {
                          label: "NTC Los Olivos 3",
                          value: "NTC Los Olivos 3",
                        },
                        {
                          label: "NTC Los Olivos 4",
                          value: "NTC Los Olivos 4",
                        },
                        {
                          label: "NTC Los Olivos 5",
                          value: "NTC Los Olivos 5",
                        },
                      ],
                    },
                    {
                      name: "consent",
                      inputType: "checkbox",
                      label:
                        "By signing up via text, you agree to receive recurring automated marketing messages at the phone number provided. Consent is not condition of purchase. Reply STOP to unsubscribe. Reply HELP for help. Message frequency varies. Msg & data rates map apply. View our Privacy Policy and Terms of Service.",
                    },
                    {
                      name: "Agree consent",
                      inputType: "checkbox",
                      label:
                        "I agree to Nike's Privacy Policy and Terms of Use and sign up for emails to get updates from Nike Studios on offers and your member benefits.",
                    },
                  ]
                : [
                    [
                      {
                        name: "firstName",
                        label: "First Name",
                        inputType: "text",
                        inputProps: {
                          // placeholder: "First Name",
                        },
                      },
                      {
                        name: "lastName",
                        label: "Last Name",
                        inputType: "text",
                        inputProps: {
                          // placeholder: "Last Name",
                        },
                      },
                    ],
                    {
                      name: "email",
                      inputType: "email",
                      label: "Email",
                      inputProps: {
                        // placeholder: "Email",
                      },
                    },
                    [
                      {
                        name: "gender",
                        inputType: "select",
                        label: "Gender",
                        inputProps: {
                          // placeholder: "Gender",
                        },
                        options: [
                          { label: "Male", value: "m" },
                          { label: "Female", value: "f" },
                        ],
                      },
                      {
                        name: "date",
                        inputType: "date",
                        label: "Date of Birth (MM/DD/YY)",
                        inputProps: {
                          // placeholder: "Date of Birth (MM/DD/YY)",
                          autoComplete: "off",
                          className: "date-of-birth",
                        },
                      },
                    ],
                    [
                      {
                        name: "phone",
                        label: "Phone Number",
                        inputType: "tel",
                        inputProps: {
                          // placeholder: "Phone Number",
                        },
                      },
                      {
                        name: "location",
                        label: "Studios",
                        inputType: "select",
                        inputProps: {
                          type: "select",
                          // placeholder: "NTC Los Olivos",
                          autoComplete: "off",
                        },
                        options: [
                          {
                            label: "NTC Los Olivos 1",
                            value: "NTC Los Olivos 1",
                          },
                          {
                            label: "NTC Los Olivos 2",
                            value: "NTC Los Olivos 2",
                          },
                          {
                            label: "NTC Los Olivos 3",
                            value: "NTC Los Olivos 3",
                          },
                          {
                            label: "NTC Los Olivos 4",
                            value: "NTC Los Olivos 4",
                          },
                          {
                            label: "NTC Los Olivos 5",
                            value: "NTC Los Olivos 5",
                          },
                        ],
                      },
                    ],
                    {
                      name: "consent",
                      inputType: "checkbox",
                      label:
                        "By signing up via text, you agree to receive recurring automated marketing messages at the phone number provided. Consent is not condition of purchase. Reply STOP to unsubscribe. Reply HELP for help. Message frequency varies. Msg & data rates map apply. View our Privacy Policy and Terms of Service.",
                    },
                    {
                      name: "Agree consent",
                      inputType: "checkbox",
                      label:
                        "I agree to Nike's Privacy Policy and Terms of Use and sign up for emails to get updates from Nike Studios on offers and your member benefits.",
                    },
                  ]
            }
            initialValues={initialValue}
            isFloatingLabel={true}
            isShowCancelButton={false}
            formTitle="Now let’s make you a studio member"
            isFormDirectEditable
            submitBtnTxt="Confirm"
            isShowSubmitButton={true}
            formikProps={{
              innerRef: formikRef,
              validateOnMount: true,
              validationSchema: createStudioValidationSchema,
            }}
          />
        </div>
      </Layout>
    </>
  )
}

export default StudioAccountSignup

export const query = graphql`
  query studioQuery($id: String, $navbarId: String) {
    contentfulStudio(id: { eq: $id }) {
      id
      name
      slug
      description {
        childMarkdownRemark {
          html
        }
      }
      apiId
      internal {
        type
      }
    }
    header: contentfulNavigator(id: { eq: $navbarId }) {
      ...Header
    }
    cookieConsent: contentfulCookieConsent {
      ...CookieConsent
    }
  }
`
