import { Button, FLImage, FLTable } from "@fitplan/web-components"
import { graphql, navigate } from "gatsby"
import React, { useState } from "react"
import MapImage from "../assets/icons/map.png"
import StudioLogo from "../assets/icons/studio-logo.png"
import Layout from "../components/layout"

const Studios = data => {
  const [selectedStudio, setSelectedStudio] = useState(
    data?.data?.allContentfulStudio?.edges?.[0]?.node?.slug
  )

  return (
    <Layout
      header={data?.data?.header}
      cookieConsent={data?.data?.cookieConsent}
      siteWideMeta={data?.data?.siteWideMeta}
    >
      <div className="find-studio-container">
        <div className="find-studio-map-container">
          <FLImage src={MapImage} />
        </div>
        <div className="find-studio-table-container">
          <div className="find-studio-table">
            <FLTable
              tableTitle="Find a Studio Near You"
              columns={[
                {
                  header: "",
                  Cell: ({ original }) => (
                    <>
                      <img src={StudioLogo} />
                    </>
                  ),
                },
                {
                  header: "",
                  Cell: ({ original }) => (
                    <>
                      <h5
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={() => navigate(original?.node?.slug)}
                      >
                        {original?.node?.name}
                      </h5>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            original?.node?.address?.childMarkdownRemark?.html,
                        }}
                        style={{ fontSize: "14px" }}
                      />
                    </>
                  ),
                },
                {
                  header: "",
                  Cell: ({ original }) => (
                    <>
                      <Button
                        label={`${
                          selectedStudio === original?.node?.slug
                            ? "Selected"
                            : "Select"
                        }`}
                        classes={`studio-table-button${
                          selectedStudio === original?.node?.slug
                            ? "-selected"
                            : ""
                        }`}
                        onClick={() => {
                          setSelectedStudio(original?.node?.slug)
                        }}
                      />
                    </>
                  ),
                },
              ]}
              data={data?.data?.allContentfulStudio?.edges}
              isBordered={false}
              classes={"studio-table"}
              tableMainActions={[]}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Studios

export const studioQuery = graphql`
  query ($navbarId: String!) {
    allContentfulStudio(
      filter: {
        node_locale: { eq: "en-US" }
        name: { ne: "Sample - DO NOT USE OR DELETE" }
      }
    ) {
      edges {
        node {
          id
          name
          slug
          apiId
          description {
            childMarkdownRemark {
              html
            }
          }
          address {
            childMarkdownRemark {
              html
            }
          }
          location {
            lon
            lat
          }
          apiId
        }
      }
    }
    header: contentfulNavigator(id: { eq: $navbarId }) {
      ...Header
    }
    cookieConsent: contentfulCookieConsent {
      ...CookieConsent
    }
    siteWideMeta: contentfulSite {
      googleReCaptchaSiteKey
    }
  }
`