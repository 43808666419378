import { graphql, Link } from "gatsby"
import React, { useRef, useState } from "react"
// import { FLForm, FLImage } from "@fitplan/web-components"
// import mapImg from "../assets/icons/studio-details-map.jpg"
import Layout from "../components/layout"
// import * as Yup from "yup"
import "../../src/config.css"

import StudioModules from "../components/StudioModules/studioModules"
// import ScheduleListTable from "../components/ScheduleListTable"

const StudioDetails = ({ data }) => {
  // const [validationObject, setValidationObject] = useState({})
  // const [initialValue, setInitialValue] = useState({})
  // const formikRef = useRef(null)
  // const offerSchema = Yup.object().shape(validationObject)

  return (
    <div>
      <>
        <Layout header={data?.header} cookieConsent={data?.cookieConsent} siteWideMeta={data?.siteWideMeta}>
          <div className="studio-details-container">
            {/* <div className="studio-detailes-banner-container">
              <div className="banner-text">
                <h2 className="banner-title">Location Name</h2>
                <div className="banner-description">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        data.contentfulStudio?.description?.childMarkdownRemark
                          ?.html,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        data.contentfulStudio?.address?.childMarkdownRemark
                          ?.html,
                    }}
                  />
                </div>

                <p className="banner-description">{`Cars mattis consecutetur purus sit amet fermentum. Aenean lacinia blbendum nulla se consecutetur.\n\n638 SW 5th Ave,\nportland, OR 97204\n`}</p>
                <div className="book-a-class-btn">
                  <Link
                    to={`/studios/${data?.contentfulStudio?.slug}/membership`}
                  >
                    View Packages
                  </Link>
                </div>
              </div>
              <div className="studio-details-form-container">
                <div className="studio-details-form">
                  <FLForm
                    ref={formikRef}
                    isShowLabels={true}
                    initialValues={{
                      name: "",
                      email: "",
                      phoneNumber: "",
                    }}
                    fields={[
                      {
                        name: "name",
                        inputType: "text",
                        inputProps: {
                          placeholder: "Name",
                        },
                      },
                      {
                        name: "email",
                        inputType: "text",
                        inputProps: {
                          placeholder: "Email",
                        },
                      },
                      {
                        name: "phoneNumber",
                        inputType: "tel",
                        inputProps: {
                          placeholder: "Phone Number",
                        },
                      },
                    ]}
                    initialValue={initialValue}
                    isFormDirectEditable={true}
                    submitBtnTxt="Claim Offer"
                    formikProps={{
                      innerRef: formikRef,
                      validateOnMount: true,
                      validationSchema: offerSchema,
                    }}
                  />
                </div>
              </div>
            </div> */}
            {/* <div className="studio-detailes-map-container">
              <FLImage src={mapImg} />
            </div> */}
            {/* <div className="studio-detailes-second-banner"> */}
            <StudioModules
              content={data.contentfulStudio.primaryModules}
              siteWideMeta={data?.siteWideMeta}
            />
            {/* </div> */}
            {/* <div className="studio-detailes-table">
              <ScheduleListTable
                studioId={data?.contentfulStudio?.apiId}
                studioSlug={data?.contentfulStudio?.slug}
              />
            </div> */}

            <div className="studio-detailes-carousel-container">
              <StudioModules
                content={data.contentfulStudio.secondaryModules}
                siteWideMeta={data?.siteWideMeta}
              />
            </div>
          </div>
        </Layout>
      </>
    </div>
  )
}
export default StudioDetails

export const query = graphql`
  query studioQuery($id: String, $navbarId: String!) {
    contentfulStudio(id: { eq: $id }) {
      id
      name
      slug
      description {
        childMarkdownRemark {
          html
        }
      }
      address {
        childMarkdownRemark {
          html
        }
      }
      location {
        lon
        lat
      }
      apiId
      internal {
        type
      }
      primaryModules {
        ... on ContentfulBanner {
          ...Banner
        }
        ... on ContentfulCarousel {
          ...Carousel
        }
        ... on ContentfulMarkdown {
          ...MarkDown
        }
      }
      secondaryModules {
        ... on ContentfulCarousel {
          ...Carousel
        }
        ... on ContentfulBanner {
          ...Banner
        }
        ... on ContentfulMarkdown {
          ...MarkDown
        }
      }
    }
    cookieConsent: contentfulCookieConsent {
      ...CookieConsent
    }
    header: contentfulNavigator(id: { eq: $navbarId }) {
      ...Header
    }
    siteWideMeta: contentfulSite {
      googleReCaptchaSiteKey
    }
  }
`
