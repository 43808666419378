import { FLTabbedNav } from "@fitplan/web-components"
import { graphql } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"
import ScheduleListTable from "../components/ScheduleListTable"
import { getCookie } from "../utils/utils"
import CurrentBookingTable from "../components/CurrentBookingTable"

const ScheduleList = ({ data }) => {
  const idToken = getCookie(["profile"])
  const tabItems = [{ name: "Class Schedule", id: "1" }]

  if (idToken) {
    tabItems.push({ name: "Current Bookings", id: "2" })
  }
  const [activeTab, setActiveTab] = useState(tabItems?.[0]?.id)
  const handleTabClick = tabId => {
    setActiveTab(tabId)
  }

  return (
    <>
      <Layout header={data?.header}>
        <div className="schedule-list-container">
          <div>
            <FLTabbedNav
              tabItem={tabItems}
              tabAlignment={"left"}
              handleClick={handleTabClick}
              activeTabId={activeTab}
            />
          </div>
          {activeTab === "1" && (
            <ScheduleListTable
              studioId={data?.studio?.apiId}
              studioSlug={data?.studio?.slug}
            />
          )}
          {activeTab === "2" && (
            <div>
              <CurrentBookingTable
                studioId={data?.studio?.apiId}
                studioSlug={data?.studio?.slug}
              />
            </div>
          )}
        </div>
      </Layout>
    </>
  )
}

export default ScheduleList

export const studioQuery = graphql`
  query ($id: String, $navbarId: String!) {
    header: contentfulNavigator(id: { eq: $navbarId }) {
      ...Header
    }
    cookieConsent: contentfulCookieConsent {
      ...CookieConsent
    }
    studio: contentfulStudio(id: { eq: $id }) {
      id
      name
      slug
      apiId
    }
  }
`
