import React from "react"
import { marked } from "marked"
import MarkdownIt from "markdown-it"
import MarkdownItAttrs from "markdown-it-attrs"
import { Button } from "@fitplan/web-components"
import StudioPackageBg from "../../assets/icons/studio-package-bg.png"

const StudioPlan = ({ content, onChooseButtonClick, onMoreInfoClick }) => {
  let productItems = content?.items
  const md = new MarkdownIt({
    html: true,
    xhtmlOut: true,
  }).use(MarkdownItAttrs, {
    // optional, these are default options
    leftDelimiter: "{",
    rightDelimiter: "}",
    allowedAttributes: [], // empty array = all attributes are allowed
  })

  const checkedMarkIcon = (color = "#070D0D", size = 20) => {
    return `
          <svg
            width="${size}"
            height="${size}"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="10" cy="10" r="9.5" fill="${color}" stroke="${color}" />
            <path
              d="M12.8588 8.57129L8.93025 12.4999L7.14453 10.7141"
              stroke="white"
              stroke-width="2"
              stroke-linecap="square"
            />
          </svg>
        `
  }
  const renderer = {
    listitem(body) {
      const isAdvancedFeatures = body.includes("<strong>")
        ? "advanced-features"
        : ""
      return `<li>
          ${checkedMarkIcon(isAdvancedFeatures ? "#FF2D02" : "#070D0D")}
    
          ${body}</li>`
    },
  }

  marked.use({ renderer })

  return (
    <div className="product-card-container studio-card-container">
      {productItems?.map((productItem, index) => {
        let backgroundImage =
          productItem?.backgroundImage?.desktopAsset?.[0]?.file?.url

        return (
          <div
            className="product-container studio-container"
            style={{
              backgroundImage: backgroundImage
                ? `url(${backgroundImage})`
                : `url(${StudioPackageBg})`,
            }}
            key={index}
          >
            <div className="product-detail-container">
              <p className="studio-price-text">${productItem?.prices}/Month</p>
              <div
                className="studio-package-name"
                dangerouslySetInnerHTML={{
                  __html: md.render(
                    marked(productItem?.productTitle.childMarkdownRemark.html)
                  ),
                }}
              />
              <p
                className="studio-description-text"
                dangerouslySetInnerHTML={{
                  __html: productItem?.description.description,
                }}
              ></p>
              <div className="studio-btn-container">
                <Button
                  label="Select Plan"
                  classes="banner-secondary-button-dark studio-buttons"
                  onClick={() => onChooseButtonClick(productItem)}
                />
                <Button
                  label="Learn More"
                  classes="banner-secondary-button-dark studio-buttons"
                  onClick={() => onMoreInfoClick(productItem)}
                />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default StudioPlan
