import { Button, FLModal } from "@fitplan/web-components"
import axios from "axios"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import {
  deleteCrossDomainCookies,
  getCookie,
  setCrossSubdomainCookie,
  userManager,
} from "../utils/utils"
import { marked } from "marked"
import MarkdownIt from "markdown-it"
import MarkdownItAttrs from "markdown-it-attrs"
import StudioPackageBg from "../assets/icons/studio-package-bg.png"

const PackageDetails = ({
  data,
  setPageStep,
  packageDetails,
  setShowLoader,
  setPackagePurchaseDetails,
  step,
}) => {
  let studioId = data?.contentfulStudio?.apiId
  let packageId = packageDetails?.productTitle?.id
  let backgroundImage = packageDetails?.backgroundImage?.desktopAsset?.[0]?.file?.url
  const [userId, setUserId] = useState("")
  // const [packageDetails, setPackageDetails] = useState({})
  // const [showAgreement, setShowAgreement] = useState(false)
  const [paymentUnSuccessful, setPaymentUnSuccessful] = useState(false)
  const [taxDetails, setTaxDetails] = useState()

  const accessToken = getCookie("accessToken")

  let BASE_URL = process.env.GATSBY_API_BASE_URL
  

  useEffect(() => {
    const userId = getCookie(["profile"])
    if (userId) {
      setUserId(JSON.parse(userId).sub)
    }

    if (userId) {
      setShowLoader(true)
      axios
        .get(`${BASE_URL}/studios/${studioId}/packages/${packageId}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(res => {
          setShowLoader(false)
          setTaxDetails(res.data.dueToday)
          // setPackageDetails(res?.data)
        })
        .catch(err => {
          setShowLoader(false)
          console.log(err)
        })
    } else {
      handleNikeMemberLogin()
    }
  }, [])

  // const previewAgreement = () => {
  //   setShowAgreement(true)

  //   axios
  //     .get(`${BASE_URL}/users/${userId}/studios/agreements/${studioId}`, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     })
  //     .then(res => {
  //       return res.data
  //     })
  //     .catch(err => {
  //       console.error(err)
  //     })
  // }

  // const handleAgreementRedirection = async () => {
  //   // check user has pending agreement or not
  //   const agreements = await getUserAgreements?.()
  //   const isAnyAgreementPending = agreements?.Agreements?.some(
  //     agreement => agreement.isCompleted === false
  //   )
  //   if (isAnyAgreementPending) {
  //     navigate(`/agreements`)
  //   } else {
  //     navigate(`/packages-details`)
  //   }
  //   return true
  //   // TODO: send user to buy credit screen
  // }

  // const getUserAgreements = async () => {
  //   return await axios
  //     .get(`${BASE_URL}/users/${userId}/studios/agreements/${studioId}`, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     })
  //     .then(res => {
  //       return res.data
  //     }
  //   }

  // const previewAgreement = () => {
  //   setShowAgreement(true)

  //   axios
  //     .get(`${BASE_URL}/users/${userId}/studios/agreements/${studioId}`, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     })
  //     .then(res => {
  //       return res.data
  //     })
  //     .catch(err => {
  //       console.error(err)
  //     })
  // }

  // const handleAgreementRedirection = async () => {
  //   // check user has pending agreement or not
  //   const agreements = await getUserAgreements?.()
  //   const isAnyAgreementPending = agreements?.Agreements?.some(
  //     agreement => agreement.isCompleted === false
  //   )
  //   if (isAnyAgreementPending) {
  //     navigate(`/agreements`)
  //   } else {
  //     navigate(`/packages-details`)
  //   }
  //   return true
  //   // TODO: send user to buy credit screen
  // }

  // const getUserAgreements = async () => {
  //   return await axios
  //     .get(`${BASE_URL}/users/${userId}/studios/agreements/${studioId}`, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     })
  //     .then(res => {
  //       return res.data
  //     })
  //     .catch(err => {
  //       console.error(err)
  //     })
  // }

  const handlePackagePurchase = () => {
    setShowLoader(true)

    const data = {
      schemaVersion: "1.0",
      studioId: studioId, // studio ID
      packageId: packageId, // package ID
      userId: userId, // user ID
    }
    axios
      .post(`${BASE_URL}/users/${userId}/studios/purchases`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(res => {
        setPackagePurchaseDetails(res.data)
        setPageStep("membership-successfully")
        setShowLoader(false)
      })
      .catch(err => {
        console.log(err)
        setShowLoader(false)
        setPaymentUnSuccessful(true)
      })
  }

  const getUserDetails = async () => {
    setShowLoader(true)

    axios
      .get(`${BASE_URL}/users/${userId}/studios/account`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(async res => {
        setShowLoader(false)
        if (!res?.data?.paymentProfiles) {
          return setPageStep("buy-credits")
        }

        if (res?.data?.paymentProfiles.length > 0) {
          handlePackagePurchase()
        } else {
          setPageStep("buy-credits")
        }

        // if (res?.status === 400) {
        //   navigate("/signup")
        // } else {
        //   if (res.data.isMember || res.data.isProspect) {
        //     await handleAgreementRedirection?.()
        //   }
        // }
      })
      .catch(err => {
        if (err?.response?.status === 400) {
          setShowLoader(false)
          navigate(`/studios/${data?.contentfulStudio?.slug}/signup`, {
            state: {
              packageStep: {
                packageDetails: packageDetails,
                step: step,
              },
            },
          })
        }
        // setIsUserValidateLoading(false)
        console.error(err)
      })
  }

  const handleNikeMemberLogin = () => {
    deleteCrossDomainCookies(["profile", "accessToken"])
    userManager.signinRedirect({
      state: {
        packageStep: {
          returnUrl: `/studios/${data?.contentfulStudio?.slug}/membership`,
          packageDetails: packageDetails,
          step: step,
        },
      },
      useReplaceToNavigate: true,
    })
  }

  const handleBuyNow = () => {
    if (userId) {
      getUserDetails()
    } else {
      handleNikeMemberLogin()
    }
  }

  const md = new MarkdownIt({
    html: true,
    xhtmlOut: true,
  }).use(MarkdownItAttrs, {
    // optional, these are default options
    leftDelimiter: "{",
    rightDelimiter: "}",
    allowedAttributes: [], // empty array = all attributes are allowed
  })

  return (
    <div>
      {/* {showAgreement && (
        <FLModal onClose={() => setShowAgreement(false)} modalSize="lg">
          <iframe
            className="iframe"
            width="100%"
            height="600px"
            src="https://app.clubready.com/present/37262KBCNJP/97394603/0"
          ></iframe>
        </FLModal>
      )} */}
      {paymentUnSuccessful && (
        <FLModal
          onClose={() => {
            setPaymentUnSuccessful(false)
            setPageStep("buy-credits")
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "25px",
            }}
          >
            <img
              src="https://cdn.shopify.com/s/files/1/2301/4381/files/1024px-OOjs_UI_icon_alert_destructive.svg_dd7d0ac6-c8e7-4043-b3a1-ceb3a4fd0daf_480x480.png?v=1589826423"
              width={"150px"}
            />
            <h3>Oh no, payment failed</h3>
            <h6>Please try again</h6>
          </div>
        </FLModal>
      )}
      <div className="pageContainer">
        <div className="user-details-booking-container">
          <div className="membership-success-container">
            <div className="success-details-container">
              <div className="payment-details-container">
                <div className="success-msg">
                  <p>Confirm Your Order</p>
                </div>
                <h5 className="payment-success-heading">
                  Please review your order information
                </h5>

                <div className="summary-container">
                  <h5 className="payment-success-heading">Summary</h5>
                  <div className="summary-card-container">
                    <div className="card-listing">
                      <div className="card-container">
                        <div className="left-container">
                          <img
                            src={
                              backgroundImage
                                ? backgroundImage
                                : StudioPackageBg
                            }
                            alt="summary-card"
                            width="100"
                            height={100}
                          />
                        </div>
                        <div className="right-container">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: md.render(
                                marked(
                                  packageDetails?.productTitle
                                    .childMarkdownRemark.html
                                )
                              ),
                            }}
                          />
                          <p>${packageDetails?.prices}/mo</p>
                        </div>
                      </div>
                    </div>
                    <div className="summary-subtotal-container">
                      <div className="subtotal-description">
                        <b>Subtotal</b>
                        <br />
                        <p>
                          Estimated Shipping
                          <br />
                          Estimated Tax
                          <br />
                        </p>
                      </div>
                      <div className="subtotal-price">
                        <b>${packageDetails?.prices}</b>
                        <p>$0.00</p>
                        <p>${taxDetails?.tax ?? "0.00"}</p>
                      </div>
                    </div>
                    <hr className="border sm" />
                    <div className="summary-ordertotal-container">
                      <div className="ordertotal-title">
                        <b>Order Total</b>
                      </div>
                      <div className="ordertotal-price">
                        <b>${packageDetails?.prices}</b>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="solid" />

                {/* <h5 className="payment-success-heading">Payment</h5>
                <p className="payment-order-confirm-text">
                  <b>Payment Method</b>
                  <br />
                  VISA 1234 5678 9012 3456
                  <br />
                  <br />
                  <b>Billing Details</b>
                  <br />
                  Isaiah S Bowling
                  <br />
                  302 Raleigh Dr
                  <br />
                  Warner Robins, GA 31088, US
                </p>
                <hr className="solid" /> */}

                <div className="account-button">
                  <Button
                    className="primary-button-dark"
                    label="Confirm Order"
                    onClick={handleBuyNow}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PackageDetails
