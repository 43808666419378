import React, { useRef, useState, useEffect } from "react"
import { Button, FLForm, FLTable, FLModal } from "@fitplan/web-components"
import * as Yup from "yup"
import axios from "axios"
import { getCookie } from "../../utils/utils"
import useWindowDimensions from "../../utils/useWindowDimensions"

const AccountSettings = ({ studioAgreements, userId, studioDetails, refreshUserProfile, setIsLoading }) => {
  let studioAddress = studioDetails?.address.join(" ")
  const { width } = useWindowDimensions()
  let BASE_URL = process.env.GATSBY_API_BASE_URL
  
  const [showAgreement, setShowAgreement] = useState(false)
  const [agreementUrl, setAgreementUrl] = useState("")
  const [isMobileView, setIsMobileView] = useState(false)
  const accessToken = getCookie("accessToken")
  const [profileUpdateSuccess, setProfileUpdateSuccess] = useState(false)

  const formikRef = useRef(null)

  useEffect(() => {
    if (width > 1024) {
      setIsMobileView(false)
    } else {
      setIsMobileView(true)
    }
  }, [width])

  const handleSubmit = async () => {
    setIsLoading(true);
    await axios
      .post(
        `${BASE_URL}/users/${userId}/studios/account`,
        {
          schemaVersion: "1.0",
          userId: userId,
          firstName: formikRef.current.values.firstName,
          lastName: formikRef.current.values.lastName,
          email: formikRef.current.values.email,
          gender: formikRef.current.values.gender,
          address: formikRef.current.values.address,
          city: formikRef.current.values.city,
          state: formikRef.current.values.state,
          zip: formikRef.current.values.zip,
          homePhone: "",
          workPhone: "",
          cellPhone: formikRef.current.values.phoneNumber,
          dateOfBirth: formikRef.current.values.dob,
          studioId: studioDetails?.studioId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(response => {
        formikRef.current?.resetForm?.()
        setIsLoading(false);
        setProfileUpdateSuccess(true);
        refreshUserProfile();
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error)
      })
  }

  const customValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Enter valid email address")
      .required("Email required"),
  })

  return (
    <div>
      {profileUpdateSuccess && (
        <FLModal
          onClose={() => {
            setProfileUpdateSuccess(false)
          }}
        >
          <div className="account-update-success-container">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtMiteBHd3Xb0Kqj8nRmCB38ilF2O37oRCunCOtoAi0XZKGY4f3FFGffO_0OxJmUoT2EQ&usqp=CAU"
              width={"150px"}
            />
            <h3>Profile Updated Successfully</h3>
          </div>
        </FLModal>
      )}
      {showAgreement && (
        <FLModal onClose={() => setShowAgreement(false)} modalSize="lg">
          <iframe
            className="iframe"
            width="100%"
            height="600px"
            src={agreementUrl}
          ></iframe>
        </FLModal>
      )}
      {studioDetails && (
        <div className="home-studio-container">
          <h6 className="home-studio-heading">Your Home Studio</h6>
          {studioAddress && (
            <div className="home-studio-content">
              <p className="home-studio-address-text">
                {studioAddress} {studioDetails?.zipCode}
              </p>
            </div>
          )}
        </div>
      )}

      <div className="home-studio-container">
        <h6 className="home-studio-heading">Personal Details</h6>
        <div className="home-studio-form-container">
          <FLForm
            ref={formikRef}
            onFormSubmit={handleSubmit}
            isShowLabels={true}
            fields={
              isMobileView
                ? [
                    [
                      {
                        name: "firstName",
                        inputType: "text",
                        inputProps: {
                          placeholder: "First Name",
                        },
                      },
                      {
                        name: "lastName",
                        inputType: "text",
                        inputProps: {
                          placeholder: "Last Name",
                        },
                      },
                    ],
                    {
                      name: "email",
                      inputType: "email",
                      inputProps: {
                        placeholder: "Email",
                      },
                    },
                    {
                      name: "phoneNumber",
                      inputType: "tel",
                      inputProps: {
                        placeholder: "Phone Number",
                      },
                    },
                    {
                      name: "address",
                      inputType: "text",
                      inputProps: {
                        placeholder: "Address",
                      },
                    },
                    {
                      name: "city",
                      inputType: "text",
                      inputProps: {
                        placeholder: "City",
                      },
                    },
                    {
                      name: "state",
                      inputType: "text",
                      inputProps: {
                        placeholder: "State",
                      },
                    },
                    {
                      name: "zip",
                      inputType: "text",
                      inputProps: {
                        placeholder: "Zip",
                      },
                    },
                    {
                      name: "gender",
                      inputType: "select",
                      inputProps: {
                        placeholder: "Gender",
                      },
                      options: [
                        { label: "Male", value: "m" },
                        { label: "Female", value: "f" },
                      ],
                    },
                    {
                      name: "dob",
                      inputType: "date",
                      inputProps: {
                        placeholder: "Birthday",
                        autoComplete: "off",
                      },
                    },
                    {
                      name: "emergencyContactName",
                      inputType: "text",
                      inputProps: {
                        placeholder: "Emergency Contact Name",
                      },
                    },
                    {
                      name: "emergencyContactPhone",
                      inputType: "tel",
                      inputProps: {
                        placeholder: "Emergency Contact Phone",
                      },
                    },
                  ]
                : [
                    [
                      {
                        name: "firstName",
                        inputType: "text",
                        inputProps: {
                          placeholder: "First Name",
                        },
                      },
                      {
                        name: "lastName",
                        inputType: "text",
                        inputProps: {
                          placeholder: "Last Name",
                        },
                      },
                    ],
                    [
                      {
                        name: "email",
                        inputType: "email",
                        inputProps: {
                          placeholder: "Email",
                        },
                      },
                      {
                        name: "phoneNumber",
                        inputType: "tel",
                        inputProps: {
                          placeholder: "Phone Number",
                        },
                      },
                    ],
                    [
                      {
                        name: "address",
                        inputType: "text",
                        inputProps: {
                          placeholder: "Address",
                        },
                      },
                      {
                        name: "city",
                        inputType: "text",
                        inputProps: {
                          placeholder: "City",
                        },
                      },
                    ],
                    [
                      {
                        name: "state",
                        inputType: "text",
                        inputProps: {
                          placeholder: "State",
                        },
                      },
                      {
                        name: "zip",
                        inputType: "text",
                        inputProps: {
                          placeholder: "Zip",
                        },
                      },
                    ],
                    [
                      {
                        name: "gender",
                        inputType: "select",
                        inputProps: {
                          placeholder: "Gender",
                        },
                        options: [
                          { label: "Male", value: "m" },
                          { label: "Female", value: "f" },
                        ],
                      },
                      {
                        name: "dob",
                        inputType: "date",
                        inputProps: {
                          placeholder: "Birthday",
                          autoComplete: "off",
                        },
                      },
                    ],
                    [
                      {
                        name: "emergencyContactName",
                        inputType: "text",
                        inputProps: {
                          placeholder: "Emergency Contact Name",
                        },
                      },
                      {
                        name: "emergencyContactPhone",
                        inputType: "tel",
                        inputProps: {
                          placeholder: "Emergency Contact Phone",
                        },
                      },
                    ],
                  ]
            }
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              address: "",
              city: "",
              state: "",
              zip: "",
              gender: "",
              dob: "",
              emergencyContactName: "",
              emergencyContactPhone: "",
            }}
            isShowCancelButton={false}
            isFormDirectEditable
            submitBtnTxt="Save Changes"
            isShowSubmitButton={true}
            formikProps={{
              innerRef: formikRef,
              validateOnMount: true,
              validationSchema: customValidationSchema,
            }}
            buttonType={"secondary"}
          />
        </div>
      </div>
      <div className="home-studio-container">
        <h6 className="home-studio-heading">Waivers</h6>
        <div className="home-studio-waivers-container">
          {studioAgreements?.length > 0 ? (
            <FLTable
              columns={[
                {
                  header: "",
                  accessor: "name",
                },
                {
                  header: "",
                  Cell: ({ original }) => (
                    <>
                      <Button
                        label={original.isCompleted ? "View" : "Sign"}
                        classes={`studio-table-button`}
                        onClick={() => {
                          setShowAgreement(true)
                          setAgreementUrl(original.url)
                        }}
                      />
                    </>
                  ),
                },
              ]}
              data={studioAgreements}
              isBordered={false}
              classes={"studio-waivers-table"}
              tableMainActions={[]}
            />
          ) : (
            <h6>No Record Found</h6>
          )}
        </div>
      </div>
    </div>
  )
}

export default AccountSettings
