exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-book-class-successful-js": () => import("./../../../src/templates/bookClassSuccessful.js" /* webpackChunkName: "component---src-templates-book-class-successful-js" */),
  "component---src-templates-buy-credits-js": () => import("./../../../src/templates/buyCredits.js" /* webpackChunkName: "component---src-templates-buy-credits-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/homePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-my-account-js": () => import("./../../../src/templates/myAccount.js" /* webpackChunkName: "component---src-templates-my-account-js" */),
  "component---src-templates-package-purchase-stepper-js": () => import("./../../../src/templates/packagePurchaseStepper.js" /* webpackChunkName: "component---src-templates-package-purchase-stepper-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-schedules-js": () => import("./../../../src/templates/schedules.js" /* webpackChunkName: "component---src-templates-schedules-js" */),
  "component---src-templates-session-details-js": () => import("./../../../src/templates/sessionDetails.js" /* webpackChunkName: "component---src-templates-session-details-js" */),
  "component---src-templates-studio-account-signup-js": () => import("./../../../src/templates/studioAccountSignup.js" /* webpackChunkName: "component---src-templates-studio-account-signup-js" */),
  "component---src-templates-studio-details-js": () => import("./../../../src/templates/studioDetails.js" /* webpackChunkName: "component---src-templates-studio-details-js" */),
  "component---src-templates-studios-js": () => import("./../../../src/templates/studios.js" /* webpackChunkName: "component---src-templates-studios-js" */)
}

