import React, { useState, useMemo, useEffect } from "react"
import Layout from "../components/layout"
import { graphql, navigate } from "gatsby"
import { getCookie } from "../utils/utils"
import UserProfile from "../assets/icons/UserProfile"
import { FLTabbedNav, FLLoader } from "@fitplan/web-components"
import AccountSettings from "../components/AccountSettings"
import StudioPlans from "../components/StudioPlans"
import NikeMembership from "../components/NikeMembership"
import axios from "axios"

const MyAccount = ({ data }) => {
  let BASE_URL = process.env.GATSBY_API_BASE_URL
  const accessToken = getCookie("accessToken")
  const [userId, setUserId] = useState("")
  const [userDetails, setUserDetails] = useState(null)
  const [studioAgreements, setStudioAgreement] = useState([])

  let content = [
    {
      id: "1",
      name: "Account Settings",
      modules: "account-settings",
    },
    {
      id: "2",
      name: "Studio Plans",
      modules: "studio-plans",
    },
    {
      id: "3",
      name: "Nike Membership",
      modules: "nike-membership",
    },
  ]

  const [pageList, setPageList] = useState(null)
  const [tabItems, setTabItems] = useState(content)
  const [activeTab, setActiveTab] = useState(tabItems?.[0]?.id)
  const [isLoading, setIsLoading] = useState(false)
  const [studioDetails, setStudioDetails] = useState(null)

  useEffect(() => {
    const userid = getCookie(["profile"])
    if (userid) {
      setUserId(JSON.parse(userid).sub)
      getUserStudioProfile(JSON.parse(userid).sub)
    } else {
      navigate("/");
    }
  }, [])

  const refreshUserProfile = () => {
    getUserStudioProfile(userId, true);
  }

  const getStudioList = async studioid => {
    await axios
      .get(`${BASE_URL}/studios`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(res => {
        const data = res.data.studios
        let details = data.find(item => item.studioId === studioid)
        setStudioDetails(details)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getUserStudioProfile = async (userid, isRefresh = false) => {
    setIsLoading(true)
    await axios
      .get(`${BASE_URL}/users/${userid}/studios/account`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(res => {
        if (!isRefresh) {
          getStudioList(res?.data?.studioId)
          getStudioAgreements(userid, res?.data?.studioId)
        } else {
          setIsLoading(false);
        }
        setUserDetails(res?.data)
      })
      .catch(err => {
        setIsLoading(false)
        console.error(err)
      })
  }

  const getStudioAgreements = async (userid, studioid) => {
    await axios
      .get(`${BASE_URL}/users/${userid}/studios/agreements/${studioid}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(res => {
        setStudioAgreement(res?.data?.Agreements)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        console.error(err)
      })
  }
  useMemo(() => {
    if (!activeTab) {
      setActiveTab(tabItems?.[0]?.id)
      const newTabItems = tabItems?.map(tabItem => {
        return {
          ...tabItem,
          modules: undefined,
        }
      })
      setTabItems(newTabItems)
    }
  }, [tabItems])

  useMemo(() => {
    let tempArr = {}
    const page = tabItems?.find(tabItem => tabItem.id === activeTab)

    if (page) {
      tempArr = page?.modules
    }
    setPageList(tempArr)
  }, [activeTab])

  const handleTabClick = tabId => {
    setActiveTab(tabId)
  }

  const renderModules = () => {
    switch (pageList) {
      case "account-settings":
        return (
          <AccountSettings
            studioAgreements={studioAgreements}
            userId={userId}
            studioDetails={studioDetails}
            refreshUserProfile={refreshUserProfile}
            setIsLoading={setIsLoading}
          />
        )
      case "studio-plans":
        return <StudioPlans userDetails={userDetails} userId={userId} />
      case "nike-membership":
        return <NikeMembership />
      default:
        return <h2>Page module not recognized</h2>
    }
  }

  return (
    <Layout header={data?.header} cookieConsent={data?.cookieConsent}>
      {isLoading && <FLLoader loaderColor="white" />}
      {userDetails && (
        <div className="profile-details-container">
          <span className="profile-avatar">
            <UserProfile />
          </span>
          <div className="profile-detail">
            <h4>
              {" "}
              Hi, {userDetails?.firstName} {userDetails?.lastName}{" "}
            </h4>
            <p>Nullam quis risus egat uena molls omare vel eu leo.</p>
          </div>
        </div>
      )}
      <div className="member-page-tabs">
        <FLTabbedNav
          tabItem={tabItems}
          handleClick={handleTabClick}
          activeTabId={activeTab}
        />
        {pageList && renderModules()}
      </div>
    </Layout>
  )
}

export default MyAccount

export const accountQuery = graphql`
  query ($navbarId: String!) {
    header: contentfulNavigator(id: { eq: $navbarId }) {
      ...Header
    }
    cookieConsent: contentfulCookieConsent {
      ...CookieConsent
    }
  }
`
