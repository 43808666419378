import * as React from "react"

const Master = props => (
  <svg
    width={34}
    height={24}
    viewBox="0 0 34 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={0.5} width={33} height={23} rx={3.5} fill="#111111" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.179 16.8292C15.9949 17.8273 14.459 18.4298 12.7807 18.4298C9.03582 18.4298 6 15.4301 6 11.7298C6 8.02948 9.03582 5.02979 12.7807 5.02979C14.459 5.02979 15.9949 5.63228 17.179 6.63033C18.363 5.63228 19.8989 5.02979 21.5773 5.02979C25.3221 5.02979 28.358 8.02948 28.358 11.7298C28.358 15.4301 25.3221 18.4298 21.5773 18.4298C19.8989 18.4298 18.363 17.8273 17.179 16.8292Z"
      fill="#ED0006"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.179 16.8292C18.6369 15.6003 19.5614 13.7717 19.5614 11.7298C19.5614 9.68783 18.6369 7.85923 17.179 6.63032C18.363 5.63228 19.8989 5.02979 21.5772 5.02979C25.3221 5.02979 28.3579 8.02948 28.3579 11.7298C28.3579 15.4301 25.3221 18.4298 21.5772 18.4298C19.8989 18.4298 18.363 17.8273 17.179 16.8292Z"
      fill="#F9A000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.179 16.8292C18.6369 15.6003 19.5614 13.7717 19.5614 11.7298C19.5614 9.68787 18.6369 7.85927 17.179 6.63037C15.7211 7.85927 14.7966 9.68787 14.7966 11.7298C14.7966 13.7717 15.7211 15.6003 17.179 16.8292Z"
      fill="#FF5E00"
    />
    <rect x={0.5} y={0.5} width={33} height={23} rx={3.5} stroke="#F2F4F7" />
  </svg>
)
export default Master
