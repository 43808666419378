import axios from "axios"
import { navigate } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { getCookie } from "../utils/utils"
import { FLForm, FLModal } from "@fitplan/web-components"
import * as Yup from "yup"

const BuyCredits = ({
  data,
  setPageStep,
  setShowLoader,
  packageDetails,
  setPackagePurchaseDetails,
}) => {
  const accessToken = getCookie("accessToken")

  const [paymentUnSuccessful, setPaymentUnSuccessful] = useState(false)
  let studioId = data?.contentfulStudio?.apiId
  let packageId = packageDetails?.productTitle?.id

  const [initialValue, setInitialValue] = useState({})
  const formikRef = useRef(null)

  const createStudioValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    address: Yup.string().required("Address is required"),
    companyDetails: Yup.string().required("Company Details is required"),
    city: Yup.string().required("City name is required"),
    state: Yup.string().required("State is required"),
    postalCode: Yup.string().required("Postal code is required"),
    country: Yup.string().required("Country name is required"),
  })

  const [isUserValidateLoading, setIsUserValidateLoading] = useState(true)

  let BASE_URL = process.env.GATSBY_API_BASE_URL
  

  useEffect(() => {
    const userProfile = getCookie(["profile"])
    if (userProfile) {
      const userId = JSON.parse(userProfile).sub
      setShowLoader(true);
      axios
        .get(`${BASE_URL}/users/${userId}/studios/wallet?page=addCardMini`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(res => {
          setShowLoader(false);
          const loadIframe = () => {
            const message = {
              eventPath: "wallet.walletTokenReady",
              walletToken: `${res?.data?.walletToken}`,
            }
            if (typeof document !== "undefined") {
              const iframe = document.createElement("iframe")
              iframe.src = res?.data?.url
              iframe.height = "200px"
              iframe.id = "card-details"
              iframe.onload = () => {
                iframe.contentWindow.postMessage(message, "*")
              }
              document.getElementById("iframe-container").appendChild(iframe)
            }
          }

          loadIframe()
        })
        .catch(err => {
          setShowLoader(false);
          console.log(err)
        })
    }
  }, [])

  const iframeRef = useRef(null)

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("message", handleIframeMessage)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("message", handleIframeMessage)
      }
    }
  }, [])

  const handlePackagePurchase = userId => {
    setShowLoader(true)
    const data = {
      schemaVersion: "1.0",
      studioId: studioId, // studio ID
      packageId: packageId, // package ID
      userId: userId, // user ID
    }
    axios
      .post(`${BASE_URL}/users/${userId}/studios/purchases`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(res => {
        setPackagePurchaseDetails(res.data)
        setPageStep("membership-successfully")
        setShowLoader(false)
      })
      .catch(err => {
        console.log(err)
        setShowLoader(false)
        setPaymentUnSuccessful(true)
      })
  }

  const getUserDetails = async () => {
    const userProfile = getCookie(["profile"])
    if (userProfile) {
      const userId = JSON.parse(userProfile).sub
      setShowLoader(true)
      axios
        .get(`${BASE_URL}/users/${userId}/studios/account`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(async res => {
          setShowLoader(false)

          if (res?.data?.paymentProfiles.length > 0) {
            handlePackagePurchase(userId);
          }
        })
        .catch(err => {
          if (err?.response?.status === 400) {
            setShowLoader(false)
            navigate(`/studios/${data?.contentfulStudio?.slug}/signup`)
          }
          console.error(err)
        })
    }
  }

  const updatePaymentDetails = paymentId => {
    const userProfile = getCookie(["profile"])
    if (userProfile) {
      const userId = JSON.parse(userProfile).sub
      const data = {
        userId: userId,
        paymentTypeId: paymentId,
        ...formikRef?.current?.values,
      }
      axios
        .put(`${BASE_URL}/users/${userId}/studios/wallet`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(res => {
          getUserDetails()
        })
        .catch(err => {
          setPageStep("package-details")
          console.log(err)
        })
    }
  }

  const handleIframeMessage = event => {
    // Process the message data
    const { type, payload } = event.data
    if (type === "formSubmit") {
      // Form submission event received from the iframe
      console.log("Form submitted in the iframe!", payload)
    }
    if (event.data.eventPath === "wallet.profileAdded" || event.data.success) {
      updatePaymentDetails(event?.data?.tokenSet?.acctToken)
      // setPageStep("package-details")

      // navigate user to book class flow
      // refreshData();
    } else if (
      event.data.eventPath === "wallet.profileAddCancelled" ||
      event.data.cancel
    ) {
      /// When user click on the profile added cancel
      // navigate user to session detail page
      // onCancel();
    }
  }

  const handleSubmit = () => {
    const loadIframe = () => {
      const message = {
        eventPath: "wallet.submit",
      }
      if (typeof document !== "undefined") {
        const iframe = document.getElementById("card-details")
        iframe.contentWindow.postMessage(message, "*")
      }
    }
    loadIframe()
  }

  return (
    <>
      {paymentUnSuccessful && (
        <FLModal
          onClose={() => {
            setPaymentUnSuccessful(false)
            // setPageStep("buy-credits")
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "25px",
            }}
          >
            <img
              src="https://cdn.shopify.com/s/files/1/2301/4381/files/1024px-OOjs_UI_icon_alert_destructive.svg_dd7d0ac6-c8e7-4043-b3a1-ceb3a4fd0daf_480x480.png?v=1589826423"
              width={"150px"}
            />
            <h3>Oh no, payment failed</h3>
            <h6>Please try again</h6>
          </div>
        </FLModal>
      )}
      <div id="payment-details-container" className="payment-container">
        {/* <iframe className="iframe" src={creditsData.url}></iframe> */}
        <div className="payment-details-title">
          <h2>PAYMENT DETAILS</h2>
        </div>
        <div
          id="iframe-container"
          ref={iframeRef}
          className="iframeContainer"
        ></div>
        <div className={`payment-details-form-container`}>
          <FLForm
            ref={formikRef}
            onFormSubmit={handleSubmit}
            isShowLabels={true}
            fields={[
              [
                {
                  name: "firstName",
                  label: "First Name*",
                  inputType: "text",
                  inputProps: {
                    // placeholder: "First Name",
                  },
                },
                {
                  name: "lastName",
                  label: "Last Name*",
                  inputType: "text",
                  inputProps: {
                    // placeholder: "Last Name",
                  },
                },
              ],
              {
                name: "address",
                inputType: "text",
                label: "Address*",
              },
              {
                name: "companyDetails",
                inputType: "text",
                label: "Optional - Company, C/O, Apt, Suite, Unit",
              },
              [
                {
                  name: "city",
                  label: "City*",
                  inputType: "text",
                },
                {
                  name: "state",
                  inputType: "select",
                  label: "State*",
                  options: [
                    { label: "Gujarat", value: "gujarat" },
                    { label: "maharashtra", value: "maharashtra" },
                    { label: "Rajasthan", value: "rajasthan" },
                    { label: "Madhya Pradesh", value: "madhya pradesh" },
                  ],
                },
                {
                  name: "postalCode",
                  label: "Postal Code*",
                  inputType: "text",
                },
                {
                  name: "country",
                  label: "Country/Region*",
                  inputType: "text",
                },
              ],
            ]}
            initialValues={initialValue}
            isFloatingLabel={true}
            isShowCancelButton={false}
            isFormDirectEditable
            submitBtnTxt="Confirm"
            isShowSubmitButton={true}
            formikProps={{
              innerRef: formikRef,
              validateOnMount: true,
              validationSchema: createStudioValidationSchema,
            }}
          />
        </div>
      </div>
    </>
  )
}

export default BuyCredits
