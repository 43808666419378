import { Button, FLLoader, FLTable } from "@fitplan/web-components"
import axios from "axios"
import React, { useEffect, useState } from "react"
import ArrowLeft from "../../assets/icons/arrow-left.png"
import ArrowRight from "../../assets/icons/arrow-right.png"
import { format, parseISO, addMinutes, parse } from "date-fns"
import { navigate } from "gatsby"

const futureMaxSchedulesDate = new Date(
  new Date().setDate(new Date().getDate() + 20)
)

const ScheduleListTable = ({ studioId, studioSlug }) => {
  const [sessionDate, setSessionDate] = useState(new Date())
  const [scheduleDate, setScheduleDate] = useState("")
  const [sessionData, setSessionData] = useState([])
  const [selectedStudio, setSelectedStudio] = useState(
    sessionData?.[0]?.studioId
  )
  const [isLoading, setIsLoading] = useState(false)

  const getOrdinalSuffix = day => {
    if (day >= 11 && day <= 13) {
      return "th"
    }
    switch (day % 10) {
      case 1:
        return "st"
      case 2:
        return "nd"
      case 3:
        return "rd"
      default:
        return "th"
    }
  }

  const handleFormatDate = () => {
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    }
    const formattedDate = sessionDate.toLocaleString("en-US", options)

    const day = sessionDate.getDate()
    const ordinalSuffix = getOrdinalSuffix(day)
    const formattedDay = `${day}${ordinalSuffix}`
    setScheduleDate(formattedDate.replace(String(day), formattedDay))
  }

  // Function to handle the previous date button click
  const prevDate = () => {
    const prevDay = new Date(sessionDate)
    prevDay.setDate(prevDay.getDate() - 1)
    setSessionDate(prevDay)
  }

  // Function to handle the next date button click
  const nextDate = () => {
    const nextDay = new Date(sessionDate)
    nextDay.setDate(nextDay.getDate() + 1)
    setSessionDate(nextDay)
  }

  const structureData = data => {
    const tempArr = []

    data?.sessions.map(session => {
      let tempObj = session
      let coachArr = []
      tempObj.availableSpot = `Available Spots: ${session?.spaces}`
      data?.classes.find(className => {
        if (className?.classInfoId === session?.classInfoId) {
          tempObj.className = className?.name
        }
      })
      session.coachIds?.map(coach => {
        data?.coaches?.map(coachList => {
          if (coachList?.coachId === coach) {
            coachArr.push(coachList?.name)
          }
        })
      })
      tempObj.coach = ` Instructor: ${coachArr.join(",")}`

      const parsedDate = parse(session?.time, "yyyy-MM-dd'T'HH:mm", new Date())
      const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm")

      const sessionTime = format(parseISO(formattedDate), "hh:mma")
      const parsedTime = parseISO(formattedDate)
      const updatedTime = addMinutes(parsedTime, session?.duration)
      const formattedTime = format(updatedTime, "hh:mma")
      tempObj.duration = `${sessionTime}-${formattedTime}`
      tempArr.push(tempObj)
    })
    setSessionData(tempArr)
    setSelectedStudio(tempArr?.[0]?.studioId)
  }

  useEffect(() => {
    setIsLoading(true)
    const year = sessionDate.getFullYear()
    const month = String(sessionDate.getMonth() + 1).padStart(2, "0")
    const day = String(sessionDate.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`
    handleFormatDate()

    let BASE_URL = process.env.GATSBY_API_BASE_URL
    axios
      .get(
        `${BASE_URL}/studios/${studioId}/sessions?from=${formattedDate}&to=${formattedDate}`
      )
      .then(res => {
        setSessionData(res.data)
        structureData(res?.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        console.log(err)
      })
  }, [sessionDate, studioId])

  return (
    <div>
      {isLoading && <FLLoader loaderColor="white" />}
      <div className="schedule-date-container">
        <p>{scheduleDate}</p>
        <div className="navigation-icon-container">
          <div
            className="navigation-icon"
            onClick={sessionDate > new Date() ? prevDate : undefined}
            style={{
              opacity: sessionDate > new Date() ? 1 : 0.6,
            }}
          >
            <img src={ArrowLeft} alt="arrow-left" />
          </div>
          <div
            className="navigation-icon"
            onClick={
              futureMaxSchedulesDate >= sessionDate ? nextDate : undefined
            }
            style={{
              opacity: futureMaxSchedulesDate >= sessionDate ? 1 : 0.6,
            }}
          >
            <img src={ArrowRight} alt="arrow-right" />
          </div>
        </div>
      </div>
      <div className="schedule-list-table-container">
        <div className="find-schedule-table">
          {sessionData?.length > 0 ? (
            <FLTable
              columns={[
                {
                  header: "",
                  accessor: "duration",
                },
                {
                  header: "",
                  accessor: "className",
                },
                {
                  header: "",
                  accessor: "availableSpot",
                },
                {
                  header: "",
                  accessor: "coach",
                },
                {
                  header: "",
                  Cell: ({ original }) => (
                    <>
                      <Button
                        label="Book"
                        classes={`studio-table-button`}
                        onClick={() => {
                          setSelectedStudio(original?.studioId)
                          navigate(
                            `/studios/${studioSlug}/sessions/${original?.sessionId}`
                          )
                        }}
                      />
                    </>
                  ),
                },
              ]}
              data={sessionData}
              isBordered={false}
              classes={"schedule-table"}
              tableMainActions={[]}
            />
          ) : (
            <h1>No Record Found</h1>
          )}
        </div>
      </div>
    </div>
  )
}

export default ScheduleListTable
