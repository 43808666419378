import axios from "axios"
import { graphql, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { getCookie } from "../utils/utils"
import Layout from "../components/layout"
import { Button, FLLoader, FLTable } from "@fitplan/web-components"

const cases = {
  SIGN_UP: "Sign up",
  AGREEMENT: "Agreement",
  BOOK_CLASS: "Book Class",
  BUY_CREDIT: "Buy Credit",
  CANCEL_BOOKING: "Cancel Booking",
}

const SessionDetails = ({ data, sessionId }) => {
  const [sessionDetails, setSessionDetails] = useState({})
  const [userId, setUserId] = useState("")
  const [checkStateObj, setCheckStateObj] = useState("")
  const accessToken = getCookie("accessToken")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const userId = getCookie(["profile"])
    if (userId) {
      setUserId(JSON.parse(userId).sub)
    }
  }, [])

  const getSessionDetails = () => {
    let BASE_URL = process.env.GATSBY_API_BASE_URL


    if (userId) {
      setIsLoading(true)
      axios
        .get(
          `${BASE_URL}/studios/${data?.studio?.apiId}/sessions/${sessionId}`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then(response => {
          setSessionDetails(response.data)
          // check user has state or not
          if (!response.data.state) {
            setIsLoading(false)
            setCheckStateObj(cases.SIGN_UP)
          } else if (response.data.state) {
            // check user has pending agreement or not
            axios
              .get(
                `${BASE_URL}/users/${userId}/studios/${data.studio.apiId}/agreements`,
                { headers: { Authorization: `Bearer ${accessToken}` } }
              )
              .then(res => {
                // is any pending agreeemnt
                setIsLoading(false)
                const pendingUserAgreements = res?.data?.Agreements.filter(
                  data => data?.isCompleted === false
                )
                if (pendingUserAgreements.length > 0) {
                  setCheckStateObj(cases.AGREEMENT)
                } else {
                  if (response.data.state?.isBooked) {
                    // show cancel booking
                    setCheckStateObj(cases.CANCEL_BOOKING)
                  } else {
                    if (response?.data?.state?.canBook) {
                      setCheckStateObj(cases.BOOK_CLASS)
                    } else if (
                      !response.data.state?.canBook &&
                      !response.data.state?.availableCredits
                    ) {
                      setCheckStateObj(cases.BUY_CREDIT)
                    }
                  }
                }
              })
              .catch(err => {
                setIsLoading(false)
                console.log(err)
              })
          }
        })
        .catch(err => {
          setIsLoading(false)
          console.log(err, "ERROR")
        })
    }
  }

  useEffect(() => {
    if (userId) {
      getSessionDetails?.()
    }
  }, [userId])

  const handleBookClass = () => {
    setIsLoading(true)
    let BASE_URL = process.env.GATSBY_API_BASE_URL


    const profile = getCookie(["profile"])
    const payloadData = {
      schemaVersion: "1.0",
      studioId: data?.studio?.apiId,
      userId: userId,
      sessionId: sessionId,
      date: new Date(),
      spotId: JSON.parse(profile)?.user_email,
      waitlist: false,
    }
    axios
      .post(`${BASE_URL}/users/${userId}/bookings`, payloadData, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(res => {
        setIsLoading(false)
        navigate("/studios/book-class-successful")
      })
      .catch(err => {
        setIsLoading(false)
        console.log(err)
      })
  }

  const handleNextButtonClick = () => {
    switch (checkStateObj) {
      case cases.SIGN_UP:
        return navigate(
          `/studios/${data.studio.slug}/sessions/${sessionId}/signup`
        )
      case cases.AGREEMENT:
        return navigate(
          `/studios/${data.studio.slug}/sessions/${sessionId}/agreements`
        )
      case cases.CANCEL_BOOKING:
        setIsLoading(true)
        return (
          sessionId &&
          data?.studio?.apiId &&
          axios
            .get(
              `${process.env.GATSBY_API_BASE_URL}/users/${userId}/bookings`,
              { headers: { Authorization: `Bearer ${accessToken}` } }
            )
            .then(async res => {
              // get booking info
              const bookingInfo = res.data?.bookings?.find(booking => {
                return (
                  booking.sessionId === sessionId &&
                  booking?.studioId === data?.studio?.apiId
                )
              })

              if (bookingInfo) {
                // call cancel book API
                await axios
                  .delete(
                    `${process.env.GATSBY_API_BASE_URL}/users/${userId}/bookings/${bookingInfo?.bookingId}`,
                    { headers: { Authorization: `Bearer ${accessToken}` } }
                  )
                  .catch(err => {
                    console.log(err)
                  })
                  getSessionDetails()
              }
              setIsLoading(false)
            })
            .catch(err => {
              setIsLoading(false)
              console.log(err)
            })
        )
      case cases.BOOK_CLASS:
        return handleBookClass()
      case cases.BUY_CREDIT:
        return navigate(
          `/studios/${data.studio.slug}/sessions/${sessionId}/buy-credits`
        )

      default:
        return <h2>Page not found</h2>
    }
  }

  return (
    <>
      <Layout header={data?.header}>
        {isLoading && <FLLoader loaderColor="white" />}
        <div className="sessions-details-page-container">
          <div className="schedule-list-table-container">
            <div className="find-schedule-table">
              <FLTable
                columns={[
                  {
                    header: "",
                    accessor: "duration",
                  },
                  {
                    header: "",
                    accessor: "capacity",
                  },
                  {
                    header: "",
                    accessor: "time",
                  },
                  {
                    header: "",
                    accessor: "bookingPolicy",
                  },
                  {
                    header: "",
                    accessor: "coachIds",
                  },
                ]}
                data={[sessionDetails]}
                isBordered={false}
                classes={"schedule-table"}
              />
            </div>
          </div>
          <div className="session-booking-button">
            {checkStateObj && (
              <Button
                className="banner-primary-button-dark"
                label={checkStateObj}
                onClick={handleNextButtonClick}
              />
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default SessionDetails

export const studioQuery = graphql`
  query ($id: String, $navbarId: String!) {
    header: contentfulNavigator(id: { eq: $navbarId }) {
      ...Header
    }
    studio: contentfulStudio(id: { eq: $id }) {
      id
      name
      slug
      apiId
    }
  }
`
