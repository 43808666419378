import * as React from "react"

const UserProfile = props => {
  return (
    <svg
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.25 0.42041C18.8738 0.42041 13.7045 5.58968 13.7045 11.9659H15.2045C15.2045 6.41811 19.7022 1.92041 25.25 1.92041V0.42041ZM13.7045 11.9659C13.7045 18.342 18.8738 23.5113 25.25 23.5113V22.0113C19.7022 22.0113 15.2045 17.5136 15.2045 11.9659H13.7045ZM25.25 23.5113C31.6262 23.5113 36.7955 18.342 36.7955 11.9659H35.2955C35.2955 17.5136 30.7978 22.0113 25.25 22.0113V23.5113ZM36.7955 11.9659C36.7955 5.58968 31.6262 0.42041 25.25 0.42041V1.92041C30.7978 1.92041 35.2955 6.41811 35.2955 11.9659H36.7955ZM2.25 50.8295V42.1931H0.75V50.8295H2.25ZM2.25 42.1931C2.25 36.6454 6.7477 32.1477 12.2955 32.1477V30.6477C5.91927 30.6477 0.75 35.817 0.75 42.1931H2.25ZM12.2955 32.1477H38.2045V30.6477H12.2955V32.1477ZM38.2045 32.1477C43.7523 32.1477 48.25 36.6454 48.25 42.1931H49.75C49.75 35.817 44.5807 30.6477 38.2045 30.6477V32.1477ZM48.25 42.1931V50.8295H49.75V42.1931H48.25Z"
        fill="#111111"
      />
    </svg>
  )
}

export default UserProfile
