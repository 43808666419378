import React from "react"
import "../config.css"

const BookClassSuccessful = ({ data }) => {
  return (
    <div>
      <div className="pageContainer">
        <div className="user-details-booking-container">
          <div className="bookingpage">
            <h1 className="pagetitle">Booking successfully</h1>
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtMiteBHd3Xb0Kqj8nRmCB38ilF2O37oRCunCOtoAi0XZKGY4f3FFGffO_0OxJmUoT2EQ&usqp=CAU" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookClassSuccessful
