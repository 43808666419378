import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import "../config.css"
import ChooseStudioPlan from "../templates/chooseStudioPlan"
import PackageDetails from "../templates/packageDetails"
import PackageBenefits from "../templates/packageBenefits"
import MembershipCompleted from "../templates/membershipSuccessful"
import BuyCredits from "../templates/buyCredits"
import BookClassSuccessful from "../templates/bookClassSuccessful"
import { FLLoader } from "@fitplan/web-components"

const PackagePurchaseStepper = ({ data, location }) => {
  const [step, setStep] = useState("choose-plan")
  const [packageDetails, setPackageDetails] = useState(null)
  const [packagePurchaseDetails, setPackagePurchaseDetails] = useState({})
  const [showLoader, setShowLoader] = useState(false)

  const setPageStep = value => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
    setStep(value)
  }

  const handlePackageDetails = value => {
    setPackageDetails(value)
  }

  const handlePackagePurchaseDetails = value => {
    setPackagePurchaseDetails(value)
  }

  useEffect(() => {
    if (location?.state?.packageStep?.step) {
      setStep('package-details')
      setPackageDetails(location?.state?.packageStep?.packageDetails)
    }
  }, [location?.state?.packageStep?.step])

  return (
    <Layout header={data?.header} cookieConsent={data?.cookieConsent}>
      {showLoader && <FLLoader loaderColor="white" />}
      {step === "choose-plan" && (
        <ChooseStudioPlan
          data={data}
          setPageStep={setPageStep}
          setPackageDetails={handlePackageDetails}
          setShowLoader={setShowLoader}
          step={step}
        />
      )}
      {step === "package-details" && (
        <PackageDetails
          data={data}
          setPageStep={setPageStep}
          packageDetails={packageDetails}
          setShowLoader={setShowLoader}
          setPackagePurchaseDetails={handlePackagePurchaseDetails}
          step={step}
        />
      )}
      {step === "package-benefits" && (
        <PackageBenefits
          data={data}
          setPageStep={setPageStep}
          packageDetails={packageDetails}
          setShowLoader={setShowLoader}
          step={step}
        />
      )}
      {step === "buy-credits" && (
        <BuyCredits
          data={data}
          setPageStep={setPageStep}
          setShowLoader={setShowLoader}
          packageDetails={packageDetails}
          setPackagePurchaseDetails={handlePackagePurchaseDetails}
        />
      )}
      {step === "book-class-successful" && <BookClassSuccessful />}
      {step === "membership-successfully" && (
        <MembershipCompleted
          data={data}
          packageDetails={packageDetails}
          setShowLoader={setShowLoader}
          packagePurchaseDetails={packagePurchaseDetails}
        />
      )}
    </Layout>
  )
}

export default PackagePurchaseStepper

export const membershipQuery = graphql`
  query ($id: String, $navbarId: String!) {
    header: contentfulNavigator(id: { eq: $navbarId }) {
      ...Header
    }
    cookieConsent: contentfulCookieConsent {
      ...CookieConsent
    }
    contentfulStudio(id: { eq: $id }) {
      id
      name
      slug
      apiId
    }
    contentfulProducts {
      id
      name
      internal {
        type
      }
      items {
        description {
          description
        }
        name
        sku
        renewal
        prices
        productIDs
        productTitle {
          id
          childMarkdownRemark {
            html
          }
        }
        backgroundImage {
          assetType
          desktopAsset {
            file {
              url
              fileName
              contentType
            }
            title
            url
          }
          mobileAsset {
            file {
              url
              fileName
              contentType
            }
            title
            url
          }
        }
      }
    }
  }
`
