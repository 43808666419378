import React, { useEffect, useState } from "react"
import axios from "axios"
import Visa from "../../assets/icons/visa"
import Amex from "../../assets/icons/amex"
import Master from "../../assets/icons/master"
import Bank from "../../assets/icons/bank"
import { FLTable } from "@fitplan/web-components"
import { getCookie } from "../../utils/utils"

const StudioPlans = ({ userDetails, userId }) => {
  const studioId = userDetails?.studioId

  let BASE_URL = process.env.GATSBY_API_BASE_URL
  
  const accessToken = getCookie("accessToken")
  const [billingHistory, setBillingHistory] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  let paymentCardIcon = userDetails?.paymentProfiles?.[0].type

  const CardTypeImg = () => {
    switch (paymentCardIcon) {
      case "Visa":
        return <Visa />
      case "Amex":
        return <Amex />
      case "Master":
        return <Master />
      default:
        return <Bank />
    }
  }
  const getPurchaseHistory = async => {
    axios
      .get(`${BASE_URL}/users/${userId}/studios/purchases`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(res => {
        setBillingHistory(res?.data)
        console.log(res?.data, "RES")
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        console.error(err, "ERROR")
      })
  }

  useEffect(() => {
    getPurchaseHistory()
  }, [])

  return (
    <div>
      <div className="home-studio-container">
        <h6 className="home-studio-heading">Current Studio Plan</h6>
        <div className="studio-plan-content">
          {userDetails?.Packages?.map((userDetail, index) => {
            const dateObj = new Date(userDetail?.nextPaymentDueDate)
            const options = {
              month: "long",
              day: "numeric",
              year: "numeric",
            }
            const nextPaymentDueDate = dateObj.toLocaleDateString(
              "en-US",
              options
            )
            return (
              <div className="studio-plan-description" key={index}>
                <div className="card-details-one">
                  <h6>{userDetail?.name}</h6>
                  <p>${parseFloat(userDetail?.price)}/month</p>
                </div>

                <hr className="solid" />
                <div className="card-details-listing">
                  <div className="card-details">
                    <p>Payment Method</p>
                    <div className="right-container">
                      {CardTypeImg()}
                      <p>**{userDetails?.paymentProfiles?.[0].last4}</p>
                    </div>
                  </div>

                  <div className="card-details">
                    <p>Next Billing Date</p>
                    <p>{userDetail?.nextPaymentDueDate ? nextPaymentDueDate : "-"}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="home-studio-container">
        <h6 className="home-studio-heading">Billing History</h6>
        <div className="billing-history-container">
          {billingHistory?.length > 0 ? (
            <FLTable
              columns={[
                {
                  header: "",
                  accessor: "name",
                },
                {
                  header: "",
                  Cell: ({ original }) => {
                    const dateObj = new Date(original?.purchaseDate)
                    const options = {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    }
                    const formattedDate = dateObj.toLocaleDateString(
                      "en-US",
                      options
                    )
                    return <div>{formattedDate}</div>
                  },
                },
                {
                  header: "",
                  Cell: ({ original }) => {
                    return <div>{`$${original?.total}.00`}</div>
                  },
                },
              ]}
              data={billingHistory}
              isBordered={false}
              classes={"billing-history-table"}
              tableMainActions={[]}
            />
          ) : (
            <h6>No Record Found</h6>
          )}
        </div>
      </div>
    </div>
  )
}

export default StudioPlans
