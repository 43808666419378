import { Button } from "@fitplan/web-components"
import axios from "axios"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import {
  deleteCrossDomainCookies,
  getCookie,
  setCrossSubdomainCookie,
  userManager,
} from "../utils/utils"
import { marked } from "marked"
import MarkdownIt from "markdown-it"
import MarkdownItAttrs from "markdown-it-attrs"
import StudioPackageBg from '../assets/icons/studio-package-bg.png';

const PackageBenefits = ({
  data,
  setPageStep,
  packageDetails,
  setShowLoader,
  step,
}) => {
  const [userId, setUserId] = useState("")
  const accessToken = getCookie("accessToken")
  // let studioId = data?.contentfulStudio?.apiId
  // let packageId = packageDetails?.productTitle?.id
  let backgroundImage = packageDetails?.backgroundImage?.desktopAsset?.[0]?.file?.url

  let BASE_URL = process.env.GATSBY_API_BASE_URL
  

  const getUserDetails = async () => {
    setShowLoader(true)
    axios
      .get(`${BASE_URL}/users/${userId}/studios/account`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(async res => {
        // if (res.data.isMember || res.data.isProspect) {
        //   await handleAgreementRedirection?.()
        // }
        setPageStep("package-details")

        // setIsUserValidateLoading(false)
        setShowLoader(false)
      })
      .catch(err => {
        if (err?.response?.status === 400) {
          navigate(`/studios/${data?.contentfulStudio?.slug}/signup`, {
            state: {
              packageStep: {
                packageDetails: packageDetails,
                step: step,
              },
            },
          })
        }
        // setIsUserValidateLoading(false)
        setShowLoader(false)
        console.log(err)
      })
  }

  const handleNikeMemberLogin = () => {
    deleteCrossDomainCookies(["profile", "accessToken"])
    userManager.signinRedirect({
      state: {
        packageStep: {
          returnUrl: `/studios/${data?.contentfulStudio?.slug}/membership`,
          packageDetails: packageDetails,
          step: step,
        },
      },
      useReplaceToNavigate: true,
    })
  }

  // const handleAgreementRedirection = async () => {
  //   // check user has pending agreement or not
  //   const agreements = await getUserAgreements?.()
  //   const isAnyAgreementPending = agreements?.Agreements?.some(
  //     agreement => agreement.isCompleted === false
  //   )
  //   console.log(isAnyAgreementPending)
  //   if (isAnyAgreementPending) {
  //     navigate(`/agreements`)
  //   } else {
  //     navigate(`/studios/${data?.studio?.slug}/packages-details`)
  //   }
  //   return true
  //   // TODO: send user to buy credit screen
  // }

  // const getUserAgreements = async () => {
  //   return await axios
  //     .get(`${BASE_URL}/users/${userId}/studios/agreements/${studioId}`, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     })
  //     .then(res => {
  //       return res.data
  //     })
  //     .catch(err => {
  //       console.error(err)
  //     })
  // }

  useEffect(() => {
    const userId = getCookie(["profile"])
    if (userId) {
      setUserId(JSON.parse(userId).sub)
    }

    // if (userId) {
    //   axios
    //     .get(`${BASE_URL}/studios/${studioId}/packages/${packageId}`, {
    //       headers: { Authorization: `Bearer ${accessToken}` },
    //     })
    //     .then(res => {
    //       // setPackageDetails(res?.data)
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // } else {
    //   // handleNikeMemberLogin()
    // }
  }, [])

  const handleBuyNow = () => {
    if (userId) {
      getUserDetails()
    } else {
      handleNikeMemberLogin()
    }
  }

  const md = new MarkdownIt({
    html: true,
    xhtmlOut: true,
  }).use(MarkdownItAttrs, {
    // optional, these are default options
    leftDelimiter: "{",
    rightDelimiter: "}",
    allowedAttributes: [], // empty array = all attributes are allowed
  })

  return (
    <>
      <div
        className="package-benefits-container"
        style={{
          backgroundImage: backgroundImage
            ? `url(${backgroundImage})`
            : `url(${StudioPackageBg})`,
        }}
      ></div>
      <div className="package-benefits-content">
        <div
          className="package-benefits-package-name"
          dangerouslySetInnerHTML={{
            __html: md.render(
              marked(packageDetails?.productTitle.childMarkdownRemark.html)
            ),
          }}
        />
        <h6>${packageDetails?.prices}/Month</h6>
        <p
          className="package-benefits-package-description"
          dangerouslySetInnerHTML={{
            __html: packageDetails?.description.description,
          }}
        ></p>
        <div className="package-benefits-button">
          <Button
            label="Select Plan"
            classes="package-benefits-banner-secondary-button-dark"
            onClick={handleBuyNow}
          />
        </div>
      </div>
    </>
  )
}

export default PackageBenefits
