import React, { useState, useEffect } from "react"
import "../config.css"
import { Button } from "@fitplan/web-components"
import { marked } from "marked"
import MarkdownIt from "markdown-it"
import MarkdownItAttrs from "markdown-it-attrs"
import { navigate } from "gatsby"
import StudioPackageBg from "../assets/icons/studio-package-bg.png"
import { getCookie } from "../utils/utils"
import axios from "axios"

const MembershipCompleted = ({
  data,
  packageDetails,
  setShowLoader,
  packagePurchaseDetails,
}) => {
  let studioId = data?.contentfulStudio?.apiId
  let packageId = packageDetails?.productTitle?.id

  let backgroundImage = packageDetails?.backgroundImage?.desktopAsset?.[0]?.file?.url

  const [userDetails, setUserDetails] = useState()
  const [taxDetails, setTaxDetails] = useState()
  const accessToken = getCookie("accessToken")

  let BASE_URL = process.env.GATSBY_API_BASE_URL
  

  const md = new MarkdownIt({
    html: true,
    xhtmlOut: true,
  }).use(MarkdownItAttrs, {
    // optional, these are default options
    leftDelimiter: "{",
    rightDelimiter: "}",
    allowedAttributes: [], // empty array = all attributes are allowed
  })

  useEffect(() => {
    const userId = getCookie(["profile"])
    if (userId) {
      setUserDetails(JSON.parse(userId))
      setShowLoader(true)
      axios
        .get(`${BASE_URL}/studios/${studioId}/packages/${packageId}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(res => {
          setShowLoader(false)
          setTaxDetails(res.data.dueToday)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [])

  const getOrderDate = () => {
    const dateObj = new Date()
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
    }
    const formattedDate = dateObj.toLocaleDateString("en-US", options)
    const formatTime = dateObj.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit', timeZoneName: 'short'})
    return `${formattedDate} at ${formatTime}`;
  }

  return (
    <div className="pageContainer">
      <div className="user-details-booking-container">
        <div className="membership-success-container">
          <div className="success-details-container">
            <div className="payment-details-container">
              <div className="success-msg">
                <p>THANK YOU!</p>
              </div>
              <h5 className="payment-success-heading">
                Your Order Was Placed Successfully.
              </h5>
              <p className="payment-order-confirm-text">
                <b>Check your email for your order confirmation.</b>
                <br />
                Your Order: {packagePurchaseDetails?.saleId} <br />
                Order Date: {getOrderDate()} <br />
                We have sent the order confirmation details to{" "}
                {userDetails?.user_email}
              </p>

              <hr className="solid" />
              <div className="summary-container">
                <h5 className="payment-success-heading">Summary</h5>
                <div className="summary-card-container">
                  <div className="card-listing">
                    <div className="card-container">
                      <div className="left-container">
                        <img
                          src={
                            backgroundImage
                              ? backgroundImage
                              : StudioPackageBg
                          }
                          alt="summary-card"
                          width="100"
                          height={100}
                        />
                      </div>
                      <div className="right-container">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: md.render(
                              marked(
                                packageDetails?.productTitle.childMarkdownRemark
                                  .html
                              )
                            ),
                          }}
                        />
                        <p>${packageDetails?.prices}/mo</p>
                      </div>
                    </div>
                  </div>
                  <div className="summary-subtotal-container">
                    <div className="subtotal-description">
                      <b>Subtotal</b>
                      <br />
                      <p>
                        Estimated Shipping
                        <br />
                        Estimated Tax
                        <br />
                      </p>
                    </div>
                    <div className="subtotal-price">
                      <b>${packageDetails?.prices}</b>
                      <p>$0.00</p>
                      <p>${taxDetails?.tax ?? "0.00"}</p>
                    </div>
                  </div>
                  <hr className="border sm" />
                  <div className="summary-ordertotal-container">
                    <div className="ordertotal-title">
                      <b>Order Total</b>
                    </div>
                    <div className="ordertotal-price">
                      <b>${packageDetails?.prices}</b>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="solid" />

              {/* <h5 className="payment-success-heading">Payment</h5>
              <p className="payment-order-confirm-text">
                <b>Payment Method</b>
                <br />
                VISA 1234 5678 9012 3456
                <br />
                <br />
                <b>Billing Details</b>
                <br />
                Isaiah S Bowling
                <br />
                302 Raleigh Dr
                <br />
                Warner Robins, GA 31088, US
              </p>
              <hr className="solid" /> */}

              <div className="account-button">
                <Button
                  className="primary-button-dark"
                  label="Go to My Account"
                  onClick={() => navigate("/my-account")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MembershipCompleted
